a.nav-link, a.navbar-brand{
    margin: 10px;
  }

  a.nav-link{
    font-weight: bold;
    font-size: 1.2rem;
  }
  
  footer{
    width: 100vw;
    text-align: center;
    height:fit-content;
    position: relative;
    bottom: 0;
    left: 0;
    color: #FFFFFF;
  }
  
  .logoNavStyle{
    height:50px;
    mix-blend-mode: multiply;
  }

.navbar-toggler{
    margin-right:10vw;
}

#renderBox{
  min-height: calc(100vh - 230px);
  display: flex;
  flex-direction: column;
  align-items: center;
}

#innerRenderBox{
  max-width: min(1000px, 100vw);

  overflow-x: hidden;
}

