.section-blur{
    backdrop-filter: blur(2px);
}

.backgroundImageWrapper{
    position:fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: -100;
}

.backgroundImage{
    width: 100%;
    height: 100%;
    object-fit:cover;
}
.row{
    --bs-gutter-x: 0;
    --bs-gutter-y: 0;
}

video{
 background-color: black;
}