
.HomeLogo{
    isolation: isolate;
    mix-blend-mode:multiply;
    width: 100%;
    height:50vh;
    object-fit: scale-down;
    position: absolute;
    top: 0px;  
}

.LogoBack{
    isolation: isolate;
    mix-blend-mode:color-dodge;
    width: 100%;
    height:50vh;
    object-fit: scale-down;
    position: absolute;
    filter: blur(200px);
    opacity: 40%;
    top: 0px;
    z-index: -1;
    
    /* center */
    
}

.logo-container{
    width: 100%;
    overflow-x: hidden;
    position: relative;
    height: 50vh;
    display: block;
}


.Opening_Line{
    color: white;
    font-size:1.5rem;
    background-color: rgb(235, 70, 70);
    padding: 5px;
}